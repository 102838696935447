import React, { Suspense, lazy, useState } from 'react';
import GridPattern from './GridPattern'; // Importa el componente GridPattern
import AnimatedTitle from './AnimatedTitle'; // Importa AnimatedTitle
import './App.css'; // Asegúrate de importar los estilos

const IconCloud = lazy(() => import('./IconCloud')); // Importa IconCloud
const Footer = lazy(() => import('./Footer')); // Importa Footer
const Menu = lazy(() => import('./Menu')); // Importa Menu

const App = () => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  return (
    <div className="scene-container" style={{ height: '100vh', opacity: 1, position: 'relative' }}>
      <GridPattern /> {/* Añade el componente GridPattern como fondo */}
      <Suspense fallback={<div>Cargando escena...</div>}>
        <Menu /> {/* Añade el Menu en el header */}
        <AnimatedTitle /> {/* Añade el AnimatedTitle */}
        <IconCloud 
          iconSlugs={[
            "typescript",
            "javascript",
            "dart",
            "java",
            "react",
            "flutter",
            "android",
            "html5",
            "css3",
            "nodedotjs",
            "express",
            "nextdotjs",
            "prisma",
            "amazonaws",
            "postgresql",
            "firebase",
            "nginx",
            "vercel",
            "testinglibrary",
            "jest",
            "cypress",
            "docker",
            "git",
            "jira",
            "github",
            "gitlab",
            "visualstudiocode",
            "androidstudio",
            "sonarqube",
            "figma"
          ]}
        /> {/* Muestra IconCloud directamente */}
        <Footer style={{ position: 'absolute', bottom: 0, width: '100%' }} /> {/* Añade el Footer */}
      </Suspense>
    </div>
  );
};

export default App;
